@import "styles/variables";
@import "styles/overrides";
@import "styles/base";

html, body, #root {
  height: 100%;
  background-color: #f2f2f2;
}

html {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

#root {
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    width: 100%;
  }
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: $color-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;

  .MuiDrawer-paperAnchorDockedLeft {
    border-width: 0;
  }

  @media screen and (max-width: 600px) {
    .drawer-mobile {
      .MuiBackdrop-root {
        background-color: transparent;
      }

      .MuiDrawer-paper {
        width: 50%;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        height: 100%;
      }
    }

    //.MuiPaper-rounded {
    //  border-radius: 0 !important;
    //}
    .MuiDialog-paperScrollPaper {
      max-height: 100%;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.Mui-focused {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.MuiTypography-root {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.MuiTypography-h6 {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.MuiTypography-body1 {
  font-family: 'Source Sans Pro', sans-serif;
}

.MuiDialog-paperWidthSm {
  max-width:  initial !important;
}

.MuiButton-root {
  text-transform: initial !important;
}

span {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.MuiTab-root {
  font-family: 'Source Sans Pro', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
  background-color: $color-silver;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
}

*, :after, :before {
  box-sizing: border-box
}

@-ms-viewport {
  width: device-width
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: .5rem;
}

p {
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  font-style: normal;
  line-height: inherit;
}

address, dl, ol, ul {
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden
}

table {
  border-collapse: collapse
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

svg {
  fill: $color-icon;
}

.font-bold {
  font-weight: bold;
}

.tab-link {
  text-decoration: none;
  color: inherit;
}

.justify-content-between {
  justify-content: space-between;
}

.Toastify__toast--success {
  background-color: #00a157;
}

.react-daterange-picker__calendar--open {
  background-color: transparent;
}

.react-calendar__navigation {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  & > *:first-child {
    border-top-left-radius: 8px;
  }


  & > *:last-child {
    border-top-right-radius: 8px;
  }
}

.react-calendar__month-view__days {
  & > *:last-child {
    border-bottom-right-radius: 8px;
  }

  & > *:nth-last-child(7) {
    border-bottom-left-radius: 8px;
  }
}

