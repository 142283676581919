@import "variables";

.form-control {
  background-image: linear-gradient(0deg, $color-main 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0);
}

.MuiFormControlLabel-root {
  margin-bottom: 0 !important;
}

.MuiFormControlLabel-label {
  color: $color-grey !important;
  font-size: 0.9rem !important;
  margin-left: 4px !important;
}

.MuiOutlinedInput-root {
  border-radius: 10px;
  background-color: $color-white;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.MuiTableRow-root:last-child {
  &:not(.MuiTableRow-head) {
    > th, td {
      border-bottom: none !important;
    }
  }
}

.MuiTabs-root {
  @media screen and (max-width: 600px){
    width: 100%;
  }
}

.MuiTab-wrapper {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
}

.MuiAppBar-root.MuiPaper-elevation4 {
  box-shadow: none;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $color-dark !important;
}

.MuiSelect-selectMenu {
  padding-left: 4px;
}

.MuiDialog-paperWidthSm {
  max-width: 650px;
}

.MuiOutlinedInput-input {
  padding-top: 15.5px !important;
  padding-bottom: 15.5px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
