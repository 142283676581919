@import "../../styles/variables";

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .sidebar-list {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 0;

    .expanded-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      color: #7a7a7a;

      & > * {
        margin: 8px 0;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        padding-left: 64px;
        align-items: flex-start;
      }
    }

    .list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        width: 100%;
        padding: 0;
        margin: $lg 0;
        cursor: default;

        @media screen and (max-width: 600px) {
          margin: 4px;
        }
        //@media screen and (max-height: 800px) {
        //  margin: 0;
        //}

        .sidebar-item-icon {
          height: 30px;
        }

        .last-sidebar-item-icon {
          height: 28px;
          position: relative;
          right: -1px;
        }



        @media screen and (max-width: 1050px){
          &:first-child {
            margin-top: 0;
          }
        }

        .list-item {
          position: relative;
          display: grid;
          grid-template-columns: 120px 180px;
          color: $color-icon;
          font-weight: 600;
          padding-top: $lg;
          padding-bottom: $lg;

          div {
            display: flex;
            justify-content: center;
            padding-left: 50px;
          }

          @media screen and (max-width: 600px) {
            grid-template-columns: 50px calc(100% - 50px);
            padding-top: 8px;
            padding-bottom: 8px;

            div {
              padding-left: 0;
            }
          }

          @media screen and (max-width: 1050px) and (min-width: 600px){
            display: flex;
            justify-content: center;
            align-items: center;

            div {
              display: flex;
              justify-content: center;
              padding-left: 0;
            }

            span {
              display: none;
            }
          }

          .MuiListItemText-root {
            flex: none;
          }

          .list-item-text {
            .MuiTypography-body1 {
              font-size: 14px;
              font-weight: 600;
            }
          }

          .list-item-text-active {
            .MuiTypography-body1 {
              font-size: 14px;
              font-weight: 700;
            }
          }
        }

        .list-item-active {
          background-color: #f2f2f2;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background-color: $color-main;
          }
        }

      }

      .MuiListItem-gutters {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: $color-silver;
        }
      }
    }

    .sidebar-logo {
      width: 70px;
      margin: $lg * 2 auto;
    }

    .active {
      fill: $color-main !important;
      color: $color-main !important;
    }

    @media screen and (max-width: 600px) {
      .sidebar-item-icon {
        width: 32px;
      }

      .sidebar-item-icon-active {
        width: 32px;
        border-color: $color-main;
        border-left: $md;

        .MuiTypography-body1 {
          font-weight: bold;
        }
      }
    }
  }

}
