// Colors
$color-main: #00a157;
$color-dark: #000000;
$color-white: #ffffff;
$color-grey: #b1b1b1;
$color-silver: #f2f2f2;
$color-danger: #d32f2f;
$color-icon: #7a7a7a;

// Spacing
$sm: 4px;
$md: 8px;
$lg: 12px;

