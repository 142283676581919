@import "variables";

// Text color
.primary {
  color: $color-main;
}
.secondary {
  color: $color-grey;
}
.danger {
  color: $color-danger;
}

// Flex-box
.d-flex {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}

// Spacing
.m-sm {
  margin: $sm;
}
.m-b-sm {
   margin-bottom: $sm;
 }
.m-t-sm {
  margin-top: $sm;
}
.m-l-sm {
  margin-left: $sm;
}
.m-r-sm {
  margin-right: $sm;
}

.m-md {
  margin: $md;
}
.m-b-md {
  margin-bottom: $md;
}
.m-t-md {
  margin-top: $md;
}
.m-l-md {
  margin-left: $md;
}
.m-r-md {
  margin-right: $md;
}

.m-lg {
  margin: $lg;
}
.m-b-lg {
  margin-bottom: $lg;
}
.m-t-lg {
  margin-top: $lg;
}
.m-l-lg {
  margin-left: $lg;
}
.m-r-lg {
  margin-right: $lg;
}

.p-sm {
  padding: $sm;
}
.p-b-sm {
  padding-bottom: $sm;
}
.p-t-sm {
  padding-top: $sm;
}
.p-l-sm {
  padding-left: $sm;
}
.p-r-sm {
  padding-right: $sm;
}

.p-md {
  padding: $md;
}
.p-b-md {
  padding-bottom: $md;
}
.p-t-md {
  padding-top: $md;
}
.p-l-md {
  padding-left: $md;
}
.p-r-md {
  padding-right: $md;
}

.p-lg {
  padding: $lg;
}
.p-b-lg {
  padding-bottom: $lg;
}
.p-t-lg {
  padding-top: $lg;
}
.p-l-lg {
  padding-left: $lg;
}
.p-r-lg {
  padding-right: $lg;
}

// Form
.form-group-container {
  display: flex;
  align-items: center;
  position: relative;

  > div:only-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  > div:not(:last-child) {
    margin-right: $md;
  }

  > div:last-child {
    margin-left: $md;
  }

  > img {
    height: 20px;
    width: 36px;
    padding-right: $lg;
  }
}

.form-group-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// Other
.badge {
  background: $color-silver;
  color: $color-grey;
  background: #f2f2f2;
  padding: $sm $md;
  margin-left: $md;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.font-size-sm {
  font-size: 0.8rem;
}
.font-size-lg {
  font-size: 1.2rem;
}

.bold {
  font-weight: bold;
}

.underline {
  border-bottom: 1px solid #f2f2f2;
}

.icon {
  fill: #A7A7A7;
  max-height: 20px;
}

.auth-label {
  @media screen and (max-width: 600px) {
    padding-top: 24px
  }
}
